import { FormioSidebarStore } from '@platform/formiojs-react';
import { createBrowserHistory, History } from 'history';
import { action, observable } from 'mobx';
import { AppTheme, RootStoreProps, Services, ThemeOverrides } from '../types';
import { Api } from './Api';
import { AuthenticationStore } from './AuthenticationStore';
import { AuthorizationStore } from './AuthorizationStore';
import { CatalogStore } from './CatalogStore';
import { CategoriesOfSubjectsStore } from './CategoriesOfSubjectsStore';
import { CommissionSessionStore } from './CommissionSessionStore';
import { CommissionStore } from './CommissionStore';
import { ErrorStore } from './ErrorStore';
import { FormioStore } from './FormioStore';
import { IntlStore } from './IntlStore';
import { ModulesStore } from './ModulesStore';
import { NotificationStore } from './NotificationStore';
import { PersonStore } from './PersonStore';
import { PfTemplateStore } from './PfTemplateStore';
import { PresetStore } from './PresetStore';
import { RegistryStore } from './RegistryStore';
import { SubjectStore } from './SubjectStore';
import { UserStore } from './UserStore';

export class RootStore {
    @observable api: Api;
    @observable authenticationStore: AuthenticationStore;
    @observable authorizationStore: AuthorizationStore;
    @observable intlStore: IntlStore;
    @observable history: History;
    @observable personStore: PersonStore;
    @observable formioStore: FormioStore;
    @observable formioSidebarStore: FormioSidebarStore;
    @observable subjectStore: SubjectStore;
    @observable categoriesOfSubjectsStore: CategoriesOfSubjectsStore;
    @observable userStore: UserStore;
    @observable catalogStore: CatalogStore;
    @observable pfTemplateStore: PfTemplateStore;
    @observable commissionStore: CommissionStore;
    @observable commissionSessionStore: CommissionSessionStore;
    @observable notificationStore: NotificationStore;
    @observable modulesStore: ModulesStore;
    @observable errorStore: ErrorStore;
    @observable presetStore: PresetStore;
    @observable registryStore: RegistryStore;

    @observable env: RootStoreProps['env'] = {};
    @observable features: RootStoreProps['features'] = {};
    @observable themeOverrides: ThemeOverrides = {} as ThemeOverrides;
    @observable appTheme?: AppTheme;

    @observable logo: string = '';
    @observable background: string = '';

    services: Services = {
        formVersionLink: (formVersionId?: string) => {
            return `${this.env.formServiceUrl}/form-version/${formVersionId}`;
        },
        lifecycleProcessLink: (processId?: string) => {
            return `${this.env.lifeCycleServiceUrl}/api/process/details/${processId}`;
        },
    };

    constructor(props: RootStoreProps) {
        this.setObservables(props);

        this.api = new Api(this);
        this.authenticationStore = new AuthenticationStore(this);
        this.authorizationStore = new AuthorizationStore(this);
        this.intlStore = new IntlStore(this);
        this.notificationStore = new NotificationStore();
        this.errorStore = new ErrorStore(this);
        this.history = createBrowserHistory();
        this.personStore = new PersonStore(this);
        this.formioStore = new FormioStore(this);
        this.formioSidebarStore = new FormioSidebarStore();
        this.subjectStore = new SubjectStore(this);
        this.categoriesOfSubjectsStore = new CategoriesOfSubjectsStore(this);
        this.userStore = new UserStore(this);
        this.catalogStore = new CatalogStore(this);
        this.pfTemplateStore = new PfTemplateStore(this);
        this.commissionStore = new CommissionStore(this);
        this.commissionSessionStore = new CommissionSessionStore(this);
        this.modulesStore = new ModulesStore(this);
        this.presetStore = new PresetStore(this);
        this.registryStore = new RegistryStore(this);

        this.loadBackgroundImage();
    }

    @action.bound
    setObservables(props: RootStoreProps): void {
        const { env, features, themeOverrides } = props;
        this.env = env;
        this.features = features;
        this.themeOverrides = themeOverrides;
    }

    @action.bound
    setAppTheme(appTheme: AppTheme): void {
        this.appTheme = appTheme;
        this.formioStore.addComponentsWithMaterialTheme();
    }

    @action.bound
    loadBackgroundImage() {
        if (!this.env.backgroundName) {
            return;
        }

        import(`../resources/images/${this.env.backgroundName}`).then((image) => {
            this.background = image.default;
        });
    }
}
