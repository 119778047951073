import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { SessionProcedureAttributeTableModel, SessionProcedureModel } from '../../../models';
import { ProcedureDialog } from './ProcedureDialog';
import { SessionProcedureInfo } from './SessionProcedureInfo';
import { SessionProceduresList } from './SessionProceduresList';

export type SessionProceduresSettingProps = {
    model: SessionProcedureModel;
    procedureAttributeTableModel: SessionProcedureAttributeTableModel;
};

export const SessionProceduresSetting = observer((props: SessionProceduresSettingProps): JSX.Element => {
    const { model, procedureAttributeTableModel } = props;
    const { activeProcedure, loadProcedureRows } = model;
    const intl = useIntl();

    return (
        <React.Fragment>
            <Switch>
                <Route exact path={clientRoute.sessionProcedureCreate}>
                    <ProcedureDialog
                        loadProcedureRows={loadProcedureRows}
                        headerMessage={intl.formatMessage({ id: 'commissionSession.newProcedure' })}
                        submitMessage={intl.formatMessage({ id: 'commissionSession.createProcedure' })}
                    />
                </Route>
                <Route exact path={clientRoute.sessionProcedureEdit}>
                    <ProcedureDialog
                        loadProcedureRows={loadProcedureRows}
                        headerMessage={intl.formatMessage({ id: 'commissionSession.editProcedure' })}
                        submitMessage={intl.formatMessage({ id: 'common.save' })}
                    />
                </Route>
            </Switch>

            <Grid item container direction="column" spacing={10}>
                <Grid item>
                    <SessionProceduresList model={model} />
                </Grid>

                {activeProcedure && (
                    <Grid item>
                        <SessionProcedureInfo
                            model={model}
                            procedureAttributeTableModel={procedureAttributeTableModel}
                        />
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
});
