import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { TransitionDialogProps } from './TransitionDialog';

export const LifeCycleTransitionConfirmationDialog = observer((props: TransitionDialogProps): JSX.Element => {
    const {
        transition,
        isModalOpen,
        lifeCycleTransition,
        handleErrorTransition,
        handleSuccessTransition,
        setModalIsClosed,
    } = props;
    const intl = useIntl();

    const handleConfirm = (): Promise<void> => {
        return lifeCycleTransition()
            .then(handleSuccessTransition)
            .catch(handleErrorTransition)
            .finally(setModalIsClosed);
    };

    return (
        <ConfirmationDialog
            id="confirm-transition"
            setIsClosed={setModalIsClosed}
            onConfirm={handleConfirm}
            message={intl.formatMessage({ id: 'common.lifeCycleConfirmText' }, { toState: transition.toStateTitle })}
            isOpen={isModalOpen}
            title={intl.formatMessage({ id: 'common.lifeCycleConfirmTitle' })}
            keepMounted={true}
            confirmText={transition.params.title}
            className="t-transition-dialog"
        />
    );
});
