import { action, observable } from 'mobx';
import { SeverityType } from '../../stores';
import { MessageType, NotificationModel } from './NotificationModel';

export class NotificationListModel {
    @observable notifications: Array<NotificationModel> = [];

    @action.bound
    pushNotification(title: string, severity: SeverityType): void {
        const notification = new NotificationModel();
        const messageDetails: MessageType = { title, severity };
        if (this.notifications.length >= 3) {
            this.notifications.forEach((m) => {
                m.closeNotification();
            });
            setTimeout(() => {
                this.notifications = [];
                notification.load(messageDetails);
                this.notifications.push(notification);
            }, 300);
        } else {
            notification.load(messageDetails);
            this.notifications.push(notification);
            setTimeout(() => {
                if (this.notifications[this.notifications.length - 1]?.id) {
                    this.deleteNotification(this.notifications[this.notifications.length - 1].id);
                }
            }, 3000);
        }
    }

    @action.bound
    deleteNotification(id: string): void {
        const notification = this.notifications.find((n) => n.id === id);
        const indexNotification = this.notifications.findIndex((m) => m.id === id);
        notification?.closeNotification();
        setTimeout(() => {
            this.notifications.splice(indexNotification, 1);
        }, 300);
    }
}
