const commissionSession: Record<string, string | Record<string, string | Record<string, string>>> = {
    listTitle: 'Заседания',
    create: 'Создать заседание',
    newSession: 'Новое заседание',
    newProcedure: 'Новая процедура',
    createProcedure: 'Добавить процедуру',
    editProcedure: 'Редактировать процедуру',
    fields: {
        title: 'Наименование',
        process: 'ЖЦ',
        form: 'Форма основных сведений заседания',
        number: 'Номер',
        state: 'Состояние',
        commission: 'Комиссия',
        categories: 'Категории',
        startDate: 'Дата начала',
        endDate: 'Дата окончания',
    },
    filters: {
        attribute: 'Фильтры атрибутов',
        procedure: 'Фильтры результатов предыдущих процедур',
    },
    procedureFields: {
        title: 'Наименование процедуры',
        descr: 'Описание',
        process: 'ЖЦ процедуры',
        format: 'Формат процедуры',
        method: 'Способ оценки',
        members: 'Участники процедуры',
    },
    actions: {
        deleteSession: 'Удалить заседание',
        editSession: 'Редактировать заседание',
        toProcess: 'К проведению заседания',
    },
    pagingInfo:
        'Всего {count, number} заседан{count, plural, one {ия} few {ии} other {ий}}. ' +
        'Отображены c {from, number} по {to, number}',
    confirmDeletionInfoText: 'Вы действительно хотите удалить заседание "{title}"?',
    confirmProcedureDeletionInfoText: 'Вы действительно хотите удалить процедуру "{title}"?',
    blocksTitle: {
        commonForm: 'Общие сведения',
        rulesForm: 'Правила проведения заседания',
        generalizationTable: 'Таблица обобщения',
        procedures: 'Процедуры заседания',
        commissionInfo: 'Информация о комиссии',
    },

    generalizationTable: {
        addAttribute: 'Добавить обобщённый атрибут',
        dialogAddTitle: 'Добавление атрибута',
        dialogEditTitle: 'Редактирование атрибута',
        attributeTitle: 'Наименование обобщенного атрибута',
        subjectAttribute: 'Атрибут объекта "{category}"',
        confirmDeletionInfoText: 'Вы действительно хотите удалить обобщенный атрибут "{title}"?',
        tooltip: 'Необходимо заполнить таблицу обобщения для заседания',
    },

    sessionProcedureTransition: {
        transitionError:
            'Не для всех объектов принято общее решение. Вы можете воспользоваться правом решающего голоса',
        confirmTransition: 'Оставить неопределённые решения',
        cancelTransition: 'Воспользоваться решающим голосом',
    },

    sessionProcedureTable: {
        title: 'Таблица объектов рассмотрения',
        attribute: 'Обобщенный атрибут из таблицы обобщения ',
        confirmDeletionInfoText: 'Вы действительно хотите удалить из процедуры обобщенный атрибут "{title}"?',
        confirmDecision: 'Подтвердить решение',
    },

    sessionProcedureSubjectList: {
        title: 'Таблица объектов рассмотрения',
        privateDecision: 'Частное решение',
        commonDecision: 'Общее решение',
        voting: 'Голосование',
        resultOfVoting: 'Результаты голосования',
        addResultOfVoting: 'Добавление результата голосования',
        voted: 'Проголосовало',
        enterResultOfVoting: 'Введите результат голосования',
        successSave: 'Ваше решение успешно сохранено',
        toVote: 'Проголосовать',
        completedVoting: 'Голосование успешно завершено',
        voteIsYetToBeProcessed: 'Ваш голос еще не обработан',
        pagingInfo:
            'Всего {count, number} объек{count, plural, one {т} few {тов} other {тов}}. ' +
            'Отображены c {from, number} по {to, number}',
        privateDecisionsDialogTitle: 'Результаты голосования',
        showAllPrivateDecisions: 'Посмотреть решения всех членов',
        privateDecisionsTable: {
            number: '№',
            member: 'ФИО члена комиссии',
            mark: 'Решение',
            noMark: 'Нет ответа',
            date: 'Дата голосования',
        },
        commentDialogTitle: 'Комментарий',
        commentDialogListTitle: 'Комментарии',
        allComments: 'Все комментарии',
        commentField: 'Комментарий',
        createComment: 'Написать комментарий',
        editComment: 'Редактировать комментарий',
        commentsTable: {
            member: 'Пользователь',
            comment: 'Комментарий',
        },
    },
    commissionState: {
        Ready: 'Ожидающая старта',
        Voting: 'Голосование',
        CommonDecisionMaking: 'Формирование общего решения',
        Finishes: 'Процедура завершена',
        Skipped: 'Процедура пропущена',
        Draft: 'Формирование',
    },
};

export default commissionSession;
