import { action, observable } from 'mobx';
import { apiConfigs } from '../../apiConfigs';
import { Api, CatalogStore, SubjectStore } from '../../stores';
import {
    GroupedIdTitle,
    IdTitle,
    RowsData,
    SubjectRow,
    SubjectRowDTO,
    SubjectsFilter,
    SubjectsSort,
    TableQueryData,
} from '../../types';
import { DateUtils } from '../../utils';
import { DateRangeFilter, InSetFilter, LikeFilter, Sort, TableModel } from '../list';

export class SubjectListModel extends TableModel<SubjectRow, SubjectsFilter, SubjectsSort> {
    @observable api: Api;
    @observable stateFilterData: GroupedIdTitle;
    @observable processFilterData: IdTitle[];
    @observable formFilterData: IdTitle[];
    @observable categoryFilterData: IdTitle[];
    @observable commissionSessionsOptions: IdTitle[];
    @observable private subjectStore: SubjectStore;

    constructor(api: Api, catalogStore: CatalogStore, subjectStore: SubjectStore) {
        const filter = {
            identifier: new LikeFilter(),
            title: new LikeFilter(),
            processCode: new InSetFilter(),
            formCode: new InSetFilter(),
            created: new DateRangeFilter(),
            categoryIds: new InSetFilter(),
            commissionSessions: new InSetFilter(),
            state: new InSetFilter(),
        };

        const sort = {
            identifier: new Sort(),
            title: new Sort(),
            created: new Sort('desc'),
            category: new Sort(),
            expertise: new Sort(),
        };

        super(filter, sort);

        this.api = api;
        this.stateFilterData = {};
        this.commissionSessionsOptions = [];
        this.getFilterData();

        this.categoryFilterData = catalogStore.getSelectOptions(apiConfigs.categorySelectOptions);

        this.formFilterData = catalogStore.getFilterData(apiConfigs.loadFormsFilterData('Subject'));
        this.processFilterData = catalogStore.getFilterData(apiConfigs.loadProcessFilterData);
        this.subjectStore = subjectStore;
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<SubjectRow>> {
        return this.subjectStore
            .loadSubjectList(queryData)
            .then(({ rows, count }) => ({ rows: rows.map(this.mapDtoToRow), count }));
    }

    @action.bound
    getFilterData(): void {
        this.api
            .client(apiConfigs.loadStateFilterData)
            .then((r) => r.data)
            .then((data) => {
                this.stateFilterData = data.statesByProcess;
                this.commissionSessionsOptions = data.commissionSessions;
            });
    }

    @action.bound
    mapDtoToRow(dto: SubjectRowDTO): SubjectRow {
        const created = new Date(dto.created);
        const parsedFields = {
            ...(DateUtils.isValidDate(created) && { created }),
        };

        return {
            id: dto.id,
            identifier: dto.identifier,
            title: dto.title,
            processTitle: dto.processTitle,
            formTitle: dto.formTitle,
            state: dto.state,
            session: dto.session,
            category: dto.category,
            ...parsedFields,
        };
    }
}
