import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { TotObjectHeaderLabel, TotObjectHeaderValue } from '../../../components';
import { CommissionSessionModel } from '../../../models';
import { getOrElse } from '../../../utils';

export type CommissionSessionDescriptionProps = {
    commissionSessionModel: CommissionSessionModel;
};

export const CommissionSessionDescription = observer((props: CommissionSessionDescriptionProps): JSX.Element => {
    const { commissionSessionModel } = props;

    return (
        <Grid item container justify="space-between">
            <Grid item container spacing={5}>
                <Grid item>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item container spacing={7}>
                            <Grid item container alignItems="baseline" spacing={2}>
                                <Grid item>
                                    <TotObjectHeaderLabel>
                                        <FormattedMessage id="commissionSession.fields.number" />
                                    </TotObjectHeaderLabel>
                                </Grid>
                                <Grid item className="t-number">
                                    <TotObjectHeaderValue>{commissionSessionModel.identifier}</TotObjectHeaderValue>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={7}>
                            <Grid item container alignItems="baseline" spacing={2}>
                                <Grid item>
                                    <TotObjectHeaderLabel>
                                        <FormattedMessage id="commissionSession.fields.startDate" />
                                    </TotObjectHeaderLabel>
                                </Grid>
                                <Grid item className="t-startDate">
                                    <TotObjectHeaderValue>
                                        {getOrElse(
                                            commissionSessionModel.startDate,
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <FormattedDate value={commissionSessionModel.startDate} />
                                                </Grid>
                                                <Grid item>
                                                    <FormattedTime value={commissionSessionModel.startDate} />
                                                </Grid>
                                            </Grid>,
                                        )}
                                    </TotObjectHeaderValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item container spacing={7}>
                            <Grid item container alignItems="baseline" spacing={2}>
                                <Grid item>
                                    <TotObjectHeaderLabel>
                                        <FormattedMessage id="commissionSession.fields.state" />
                                    </TotObjectHeaderLabel>
                                </Grid>
                                <Grid item className="t-state">
                                    <TotObjectHeaderValue>
                                        {commissionSessionModel.metaInfo.stateTitle}
                                    </TotObjectHeaderValue>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={7}>
                            <Grid item container alignItems="baseline" spacing={2}>
                                <Grid item>
                                    <TotObjectHeaderLabel>
                                        <FormattedMessage id="commissionSession.fields.endDate" />
                                    </TotObjectHeaderLabel>
                                </Grid>
                                <Grid item className="t-endDate">
                                    <TotObjectHeaderValue>
                                        {getOrElse(
                                            commissionSessionModel.endDate,
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <FormattedDate value={commissionSessionModel.endDate} />
                                                </Grid>
                                                <Grid item>
                                                    <FormattedTime value={commissionSessionModel.endDate} />
                                                </Grid>
                                            </Grid>,
                                        )}
                                    </TotObjectHeaderValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});
