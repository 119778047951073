import { FileDTO } from './file';

export type PfTemplateDTO = {
    id: string;
    title: string;
    created: string; // date
    fileDTO: FileDTO;
};

export type PfTemplate = {
    title: string;
    file?: File;
};

export enum PrintFormField {
    title = 'title',
    file = 'file',
}
