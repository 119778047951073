import { Container, Grid } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { useStore } from '../../hooks';
import { FooterContactInformation } from './FooterContactInformation';
import { FooterTypography } from './FooterTypography';

const FooterWrapper = withTheme(
    styled.footer`
        position: relative;
        padding: ${({ theme }) => `${theme.spacing(3.75)}px 0`};
        background-color: ${({ theme }) => theme?.variables.palette.appBar.mainContrast};
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: ${({ theme }) => theme?.variables.palette.appBar.mainContrast};
        }
    `,
);

export const Footer = observer((): JSX.Element => {
    const { env, intlStore } = useStore();
    const copyright = intlStore.locale === 'en' ? env.totCopyrightEn : env.totCopyrightRu;

    return (
        <FooterWrapper>
            <Container maxWidth="lg">
                <Grid container direction="row" wrap="nowrap" alignItems="center" justify="space-between" spacing={3}>
                    <Grid item>
                        <FooterTypography component="small" color="primary">
                            {copyright}
                        </FooterTypography>
                    </Grid>
                    <FooterContactInformation />
                </Grid>
            </Container>
        </FooterWrapper>
    );
});
