import { SelectData } from '@platform/ttable';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { action, IObservableArray, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { UserNameModel } from '../models';
import { CatalogDTO, CodeTitle, IdTitle, UserPersonDTO } from '../types';
import { Api } from './Api';
import { IntlStore } from './IntlStore';
import { RootStore } from './RootStore';

export class CatalogStore {
    @observable rootStore: RootStore;
    @observable api: Api;
    @observable intlStore: IntlStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
        this.intlStore = rootStore.intlStore;
    }

    @action.bound
    catalogItems(catalogCode: string): CodeTitle[] {
        const items: IObservableArray<CodeTitle> = observable.array();
        this.api
            .client(apiConfigs.catalogItems(catalogCode, this.intlStore.locale))
            .then((r) => r.data)
            .then((data: CodeTitle[]) => items.replace(data));
        return items;
    }

    @action.bound
    getFilterData(requestConfig: AxiosRequestConfig): IdTitle[] {
        const items: IObservableArray<IdTitle> = observable.array();

        this.api
            .client(requestConfig)
            .then((r) => r.data)
            .then(
                action((data: CodeTitle[]) => {
                    items.replace(
                        data.map(({ code, title }) => ({
                            id: code,
                            title,
                        })),
                    );
                }),
            );

        return items;
    }

    @action.bound
    getSelectData(requestConfig: AxiosRequestConfig): Promise<IdTitle[]> {
        return this.api
            .client(requestConfig)
            .then((r) => r.data)
            .then((data: CodeTitle[]) => {
                return data.map(({ code, title }) => ({
                    id: code,
                    title,
                }));
            });
    }

    @action.bound
    getSelectOptions(requestConfig: AxiosRequestConfig): IdTitle[] {
        const items: IObservableArray<IdTitle> = observable.array();
        this.api
            .client(requestConfig)
            .then((r) => r.data)
            .then((data: IdTitle[]) => items.replace(data));
        return items;
    }

    @action.bound
    users(): CodeTitle[] {
        const items: IObservableArray<CodeTitle> = observable.array();
        this.api
            .client(apiConfigs.usersByRole('User'))
            .then((r) => r.data)
            .then((dtos: UserPersonDTO[]) => dtos.map((dto) => new UserNameModel().load(dto).asCodeTitle))
            .then((models) => items.replace(models));
        return items;
    }

    @action.bound
    fetchSelectDataByUrl(url: string): Promise<SelectData> {
        return this.api
            .client({
                url: url,
                method: 'GET',
            })
            .then((response: AxiosResponse) => response.data);
    }

    @action.bound
    fetchCatalogForTTable(catalogCode: string, lang: string): Promise<CodeTitle[]> {
        return this.api
            .client(apiConfigs.catalogItems(catalogCode, lang))
            .then((response) => response.data)
            .then((arr) =>
                arr.map((item: CatalogDTO) => ({ code: `${item.code}@${item.catalogCode}`, title: item.title })),
            );
    }
}
