import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
//TODO: интегрировать в intlStore
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import { FeaturesProvider } from 'feature-toggle-jsx';
import { Provider } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Router } from 'react-router-dom';
import { App } from './App';
import { TotIntlProvider } from './components';
import { ru } from './constants';
import { StoreContext } from './contexts';
import { createAppTheme } from './createThemes';
import { useStore } from './hooks';
import { RootStore } from './stores';
import { AppTheme, RootStoreProps } from './types';

const RootUnwrapped = observer((): JSX.Element => {
    const { appTheme, intlStore } = useStore();
    return (
        <ThemeProvider theme={appTheme as AppTheme}>
            <TotIntlProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={intlStore.locale === ru ? ruLocale : enLocale}>
                    <App />
                </MuiPickersUtilsProvider>
            </TotIntlProvider>
        </ThemeProvider>
    );
});

const RootWithFeatures = observer((): JSX.Element => {
    const { features } = useStore();
    return (
        <FeaturesProvider features={features as any}>
            <RootUnwrapped />
        </FeaturesProvider>
    );
});

export const Root = (props: RootStoreProps): JSX.Element => {
    const { createAppThemeOptions } = props;
    const store = new RootStore(props);
    const theme = createAppTheme(store, createAppThemeOptions);
    store.setAppTheme(theme);
    return (
        <Router history={store.history}>
            <Provider {...store}>
                <StoreContext.Provider value={store}>
                    <RootWithFeatures />
                </StoreContext.Provider>
            </Provider>
        </Router>
    );
};
