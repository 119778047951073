import { action, observable } from 'mobx';
import { CommissionSessionProcessDTO } from '../../types';
import { DateUtils } from '../../utils';
import { MetaInfoModel } from '../MetaInfo';
import { UserNameModel } from '../person';
import { CommissionSessionModel } from './CommissionSessionModel';

export class CommissionSessionProcessModel implements CommissionSessionModel {
    @observable id: string;
    @observable identifier = '';
    @observable title = '';
    @observable metaInfo: MetaInfoModel = new MetaInfoModel();
    @observable startDate?: Date;
    @observable endDate?: Date;
    @observable members: UserNameModel[] = [];
    @observable activeProcedureTitle = '';

    constructor(id: string) {
        this.id = id;
    }

    @action.bound
    load(dto: CommissionSessionProcessDTO): void {
        this.id = dto.id;
        this.title = dto.title;
        this.identifier = dto.identifier;
        this.activeProcedureTitle = dto.activeProcedureTitle;
        this.metaInfo.load(dto.metaInfo);
        this.members = dto.commissionMembers.map((m) => new UserNameModel().load(m));

        const startDate = new Date(dto.startDate || '');
        if (DateUtils.isValidDate(startDate)) {
            this.startDate = startDate;
        }

        const endDate = new Date(dto.endDate || '');
        if (DateUtils.isValidDate(endDate)) {
            this.endDate = endDate;
        }
    }
}
