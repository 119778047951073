import { ExpansionPanel, Grid, TextField, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    ClearFilterButton,
    Select,
    TotObjectFilterPanelDetails,
    TotObjectFilterPanelSummary,
} from '../../../components';
import { AutocompleteProps, pickerProps, textFieldProps } from '../../../constants';
import { useStore } from '../../../hooks';
import { SubjectListModel } from '../../../models';

export type SubjectListFilterPanelProps = {
    model: SubjectListModel;
};

export const SubjectListFilterPanel = observer((props: SubjectListFilterPanelProps): JSX.Element => {
    const { model } = props;
    const { intlStore: intl } = useStore();
    const { filtering } = model;

    return (
        <ExpansionPanel>
            <TotObjectFilterPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="subtitle2">
                    <FormattedMessage id="common.filters" />
                </Typography>
            </TotObjectFilterPanelSummary>
            <TotObjectFilterPanelDetails>
                <Grid container direction="column" spacing={6}>
                    <Grid item container spacing={6}>
                        <Grid item xs={4}>
                            <TextField
                                {...textFieldProps}
                                label={<FormattedMessage id="subject.fields.identifier" />}
                                placeholder={intl.formatMessage('common.all')}
                                value={filtering.identifier.value}
                                onChange={filtering.identifier.onChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                {...textFieldProps}
                                label={<FormattedMessage id="subject.fields.title" />}
                                placeholder={intl.formatMessage('common.all')}
                                value={filtering.title.value}
                                onChange={filtering.title.onChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                values={filtering.state.values}
                                label={<FormattedMessage id="subject.fields.state" />}
                                placeholder={intl.formatMessage('common.all')}
                                selectData={model.stateFilterData}
                                onChange={filtering.state.onChange}
                                textFieldProps={{ ...textFieldProps }}
                                autoCompleteProps={{ ...AutocompleteProps }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={6}>
                        <Grid item xs={4}>
                            <Select
                                values={filtering.formCode.values}
                                label={<FormattedMessage id="subject.fields.form" />}
                                placeholder={intl.formatMessage('common.all')}
                                selectData={model.formFilterData}
                                onChange={filtering.formCode.onChange}
                                textFieldProps={{ ...textFieldProps }}
                                autoCompleteProps={{ ...AutocompleteProps }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                values={filtering.processCode.values}
                                label={<FormattedMessage id="subject.fields.process" />}
                                placeholder={intl.formatMessage('common.all')}
                                selectData={model.processFilterData}
                                onChange={filtering.processCode.onChange}
                                textFieldProps={{ ...textFieldProps }}
                                autoCompleteProps={{ ...AutocompleteProps }}
                            />
                        </Grid>
                        <Grid item xs={4} container justify="space-between">
                            <Grid item xs={6}>
                                <DatePicker
                                    {...pickerProps}
                                    label={<FormattedMessage id="subject.fields.created" />}
                                    placeholder={intl.formatMessage('common.from')}
                                    value={filtering.created.value.from}
                                    onChange={filtering.created.onFromChange}
                                    maxDate={filtering.created.value.to}
                                    InputProps={{
                                        endAdornment: (
                                            <ClearFilterButton
                                                clear={(): void => filtering.created.onFromChange(null)}
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker
                                    {...pickerProps}
                                    placeholder={intl.formatMessage('common.until')}
                                    value={filtering.created.value.to}
                                    onChange={filtering.created.onToChange}
                                    minDate={filtering.created.value.from}
                                    InputProps={{
                                        endAdornment: (
                                            <ClearFilterButton clear={(): void => filtering.created.onToChange(null)} />
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={6}>
                            <Grid item xs={4}>
                                <Select
                                    values={filtering.categoryIds.values}
                                    label={<FormattedMessage id="subject.fields.category" />}
                                    placeholder={intl.formatMessage('common.all')}
                                    selectData={model.categoryFilterData}
                                    onChange={filtering.categoryIds.onChange}
                                    textFieldProps={{ ...textFieldProps }}
                                    autoCompleteProps={{ ...AutocompleteProps }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    values={filtering.commissionSessions.values}
                                    label={<FormattedMessage id="subject.fields.commissionSessions" />}
                                    placeholder={intl.formatMessage('common.all')}
                                    selectData={model.commissionSessionsOptions}
                                    onChange={filtering.commissionSessions.onChange}
                                    textFieldProps={{ ...textFieldProps }}
                                    autoCompleteProps={{ ...AutocompleteProps }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectFilterPanelDetails>
        </ExpansionPanel>
    );
});
