import { Box, Button, Checkbox, Dialog, FormControlLabel, Grid, IconButton, Link, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, Redirect, useLocation } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { EmailField, PasswordField } from '../../components';
import { useStore } from '../../hooks';
import { LoginModel } from '../../models';

export const LoginDialog = observer((): JSX.Element => {
    const { authenticationStore, personStore } = useStore();
    const [loginModel] = useState<LoginModel>(() => authenticationStore.loginModel);

    const location = useLocation();
    const queries = queryString.parse(location.search);
    const backUrl = queries.backUrl as string;

    return (
        <Dialog maxWidth="xs" fullWidth open={true} scroll="body">
            <Box pt={4} pr={4}>
                <Grid container justify="flex-end">
                    <Grid item>
                        <IconButton className="t-close-login" component={RouterLink} to="/">
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Box pl={12} pr={12} pb={12}>
                <Grid container justify="center">
                    <Grid item>
                        <Typography variant="h5" className="t-login-title">
                            <Box fontWeight="fontWeightBold">
                                <FormattedMessage id="authentication.loginTitle" />
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
                <Box pt={8}>
                    <form noValidate onSubmit={loginModel.login}>
                        <Grid container spacing={6} direction="column">
                            {loginModel.loginFailedMessage && (
                                <Grid item>
                                    <Typography className="t-login-failed-message" color="error">
                                        {loginModel.loginFailedMessage}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item>
                                <EmailField model={loginModel} />
                            </Grid>
                            <Grid item>
                                <PasswordField model={loginModel} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    value="top"
                                    control={
                                        <Checkbox
                                            className="t-remember-me"
                                            color="primary"
                                            onChange={(event, checked): boolean => (loginModel.rememberMe = checked)}
                                        />
                                    }
                                    label={<FormattedMessage id="authentication.rememberMe" />}
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    className="t-login"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                >
                                    <FormattedMessage id="authentication.login" />
                                </Button>
                            </Grid>
                            {/* <Grid item>
                                    <Link
                                        className="t-esia-login"
                                        component={RouterLink}
                                        to={clientRoute.esiaGetAuthUrl}
                                        underline="none"
                                    >
                                        <FormattedMessage id="authentication.loginEsia" />
                                    </Link>
                                </Grid> */}
                            <Grid item container justify="space-between">
                                <Grid item>
                                    <Link
                                        className="t-forgot-password"
                                        component={RouterLink}
                                        to={clientRoute.recoveryPassword}
                                        underline="none"
                                    >
                                        <FormattedMessage id="authentication.forgotPassword" />
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link
                                        className="t-registration"
                                        component={RouterLink}
                                        to={clientRoute.registration}
                                        underline="none"
                                    >
                                        <FormattedMessage id="authentication.register" />
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Box>
            {loginModel.loginSucceed && <Redirect push to={backUrl || personStore.redirectPath} />}
        </Dialog>
    );
});
