import { Button, Grid, Tooltip } from '@material-ui/core';
import { Done, Settings } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { ReactNode, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import {
    ActionsMenu,
    AuthorizationCheck,
    DeleteActionMenuItem,
    EditActionMenuItem,
    IconButtonLarge,
    TotObjectTransitions,
} from '../../../components';
import { useReload, useStore } from '../../../hooks';
import { CommissionSessionModel } from '../../../models';
import { RouteParamsDefault } from '../../../types';

export type CommissionSessionControlPanelProps = {
    commissionSessionModel: CommissionSessionModel;
    onSubmit?: (canSaveWithoutValidation: boolean) => void;
    updateObjectPage: () => Promise<void>;
    sessionTransition: (transitionId: string, objectId: string, validate?: boolean) => Promise<void>;
};

export const CommissionSessionControlPanel = observer((props: CommissionSessionControlPanelProps): JSX.Element => {
    const { commissionSessionModel, onSubmit = () => {}, updateObjectPage, sessionTransition } = props;
    const [reloadKey, reloadIncrement] = useReload();
    const { commissionSessionStore, notificationStore } = useStore();
    const { handleError } = notificationStore;
    const { id } = useParams<RouteParamsDefault>();
    const history = useHistory();
    const intl = useIntl();

    const onUpdateObjectPage = (): void => {
        updateObjectPage().then(reloadIncrement);
    };

    const redirectToSessionsRegistry = (): void => history.push(generatePath(clientRoute.commissionSessions));

    const deleteCommissionSession = (): Promise<void> => {
        return commissionSessionStore
            .deleteCommissionSession(commissionSessionModel.id)
            .then(redirectToSessionsRegistry)
            .catch(handleError);
    };

    const saveTransitionButton = useMemo((): JSX.Element => {
        return (
            <AuthorizationCheck
                entityCode={entities.CommissionSession}
                permCode={permissions.CommissionSession.SaveWithoutValidate}
                entityId={id}
            >
                {(allowed: boolean) => (
                    <Grid item>
                        <Button className="t-save-button" variant="contained" onClick={() => onSubmit(allowed)}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                )}
            </AuthorizationCheck>
        );
    }, [id, onSubmit]);

    const actions = [
        <AuthorizationCheck
            key="edit"
            entityCode={entities.CommissionSession}
            entityId={id}
            permCode={permissions.CommissionSession.EditSetting}
        >
            <EditActionMenuItem key="edit" path={generatePath(clientRoute.commissionSessionSettingEdit, { id })} />
        </AuthorizationCheck>,

        <AuthorizationCheck
            key="delete"
            entityCode={entities.CommissionSession}
            entityId={id}
            permCode={permissions.CommissionSession.Delete}
        >
            <DeleteActionMenuItem
                id="delete"
                key="delete"
                message={intl.formatMessage(
                    { id: 'commissionSession.confirmDeletionInfoText' },
                    { title: commissionSessionModel.title },
                )}
                onConfirm={deleteCommissionSession}
            />
        </AuthorizationCheck>,
    ];

    const renderActionItems = (): (() => ReactNode[]) => {
        return (): ReactNode[] => actions;
    };

    return (
        <Grid alignItems="center" justify="space-between" container>
            <Grid item>
                <Grid spacing={3} alignItems="center" justify="center" container>
                    <Switch>
                        <Route
                            key={clientRoute.commissionSessionSetting}
                            path={[
                                clientRoute.commissionSessionSetting,
                                clientRoute.sessionProcedureCreate,
                                clientRoute.sessionProcedureEdit,
                            ]}
                            exact
                        >
                            <Grid item>
                                <ActionsMenu renderActionItems={renderActionItems} row={null} />
                            </Grid>
                            <Grid item>
                                <Tooltip title={<FormattedMessage id="commissionSession.actions.toProcess" />}>
                                    <IconButtonLarge
                                        // @ts-ignore
                                        component={NavLink}
                                        to={generatePath(clientRoute.commissionSessionProcess, {
                                            id,
                                        })}
                                    >
                                        <Done />
                                    </IconButtonLarge>
                                </Tooltip>
                            </Grid>
                        </Route>
                        <Route
                            key={clientRoute.commissionSessionProcess}
                            path={[clientRoute.commissionSessionProcess, clientRoute.commissionSessionProcessProcedure]}
                            exact
                        >
                            <AuthorizationCheck
                                entityCode={entities.CommissionSession}
                                permCode={permissions.CommissionSession.EditSetting}
                                entityId={id}
                            >
                                <Grid item>
                                    <Tooltip title={<FormattedMessage id="common.setting" />}>
                                        <IconButtonLarge
                                            className="t-to-setting"
                                            // @ts-ignore
                                            component={NavLink}
                                            to={generatePath(clientRoute.commissionSessionSetting, {
                                                id,
                                            })}
                                        >
                                            <Settings />
                                        </IconButtonLarge>
                                    </Tooltip>
                                </Grid>
                            </AuthorizationCheck>
                        </Route>
                    </Switch>
                </Grid>
            </Grid>
            <Grid item>
                <Switch>
                    <Route
                        exact
                        path={[
                            clientRoute.commissionSessionSetting,
                            clientRoute.sessionProcedureCreate,
                            clientRoute.sessionProcedureEdit,
                        ]}
                    >
                        <AuthorizationCheck
                            key={reloadKey}
                            entityCode={entities.CommissionSession}
                            permCode={permissions.CommissionSession.SaveWithoutValidate}
                            entityId={id}
                        >
                            <Grid item container alignItems="baseline" spacing={3}>
                                <Grid item>
                                    <TotObjectTransitions
                                        key={reloadKey}
                                        objectId={id}
                                        updateObjectPage={onUpdateObjectPage}
                                        getTransitions={commissionSessionStore.getLifeCycleTransitions}
                                        lifeCycleTransition={sessionTransition}
                                    />
                                </Grid>
                            </Grid>
                        </AuthorizationCheck>
                    </Route>
                    <Route
                        exact
                        path={[clientRoute.commissionSessionProcess, clientRoute.commissionSessionProcessProcedure]}
                    >
                        <AuthorizationCheck
                            key={reloadKey}
                            entityCode={entities.CommissionSession}
                            permCode={permissions.CommissionSession.SaveWithoutValidate}
                            entityId={id}
                        >
                            {(allowed: boolean) => {
                                if (!allowed) {
                                    return (
                                        <Grid item container alignItems="baseline" spacing={3}>
                                            <Grid item>
                                                <TotObjectTransitions
                                                    key={reloadKey}
                                                    objectId={id}
                                                    updateObjectPage={onUpdateObjectPage}
                                                    getTransitions={commissionSessionStore.getLifeCycleTransitions}
                                                    lifeCycleTransition={sessionTransition}
                                                />
                                            </Grid>
                                        </Grid>
                                    );
                                }
                                return <React.Fragment />;
                            }}
                        </AuthorizationCheck>
                    </Route>
                    <Route path={clientRoute.commissionSessionSettingCreate} exact>
                        <Grid item container spacing={3}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    component={NavLink}
                                    to={generatePath(clientRoute.commissionSessions)}
                                >
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                            </Grid>

                            {saveTransitionButton}
                        </Grid>
                    </Route>
                    <Route
                        path={[
                            clientRoute.commissionSessionSettingEdit,
                            clientRoute.sessionProcedureCreate,
                            clientRoute.sessionProcedureEdit,
                        ]}
                        exact
                    >
                        <Grid item container spacing={3}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    component={NavLink}
                                    to={generatePath(clientRoute.commissionSessionSetting, { id })}
                                >
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                            </Grid>
                            {saveTransitionButton}
                        </Grid>
                    </Route>
                </Switch>
            </Grid>
        </Grid>
    );
});
