import { ExpansionPanel, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Form, FormApi, FormView } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import { clientRoute } from '../../clientRoute';
import { AuthorizationCheck, TotObjectMain, TotObjectPanelDetails, TotObjectPanelSummary } from '../../components';
import { useReload, useStore } from '../../hooks';
import { RouteParamsDefault } from '../../types';
import { CommissionSessionHeader } from './commission-session-header';
import { CommissionSessionServiceInfo } from './CommissionSessionServiceInfo';
import { GeneralizationAttributesBlock } from './generalization-table';
import { SessionProceduresSetting } from './session-procedure-setting';

export const CommissionSessionSettingPage = observer((): JSX.Element => {
    const { commissionSessionStore, notificationStore, intlStore } = useStore();
    const { id } = useParams<RouteParamsDefault>();
    const [reloadKey] = useReload();
    const history = useHistory();
    const intl = useIntl();
    const { locale } = intl;

    const model = useMemo(() => commissionSessionStore.getCommissionSessionSettingModel(id), [id]);

    const [commonFormInfoApi, setCommonFormInfoApi] = useState<FormApi>();
    const [rulesFormInfoApi, setRulesFormInfoApi] = useState<FormApi>();

    const updateObjectPage = useCallback(async (): Promise<void> => {
        const dto = await commissionSessionStore.loadSessionSetting(id);
        model.load(dto);
    }, [model, commissionSessionStore, id]);

    const onCommonFormReady = (form: FormApi): void => {
        setCommonFormInfoApi(form);
    };

    const onRulesFormReady = (form: FormApi): void => {
        setRulesFormInfoApi(form);
    };

    const onSaveSuccess = useCallback((): void => {
        history.push(generatePath(clientRoute.commissionSessionSetting, { id }));
        updateObjectPage();
    }, [updateObjectPage, history, id]);

    const validateForm = useCallback((): boolean => {
        const isCommonFormModelValid = commonFormInfoApi?.validate() || false;
        const isRulesFormModelValid = rulesFormInfoApi?.validate() || false;
        return isCommonFormModelValid && isRulesFormModelValid;
    }, [commonFormInfoApi, rulesFormInfoApi]);

    const onSubmit = useCallback(
        async (canSaveWithoutValidation: boolean): Promise<void> => {
            if (!commonFormInfoApi || !rulesFormInfoApi) {
                return;
            }

            const isFormsValid = canSaveWithoutValidation || validateForm();

            if (isFormsValid) {
                return commissionSessionStore
                    .saveForm(id, commonFormInfoApi.getSubmission(), rulesFormInfoApi.getSubmission())
                    .then(onSaveSuccess);
            }
        },
        [commonFormInfoApi, rulesFormInfoApi, commissionSessionStore, onSaveSuccess, id, validateForm],
    );

    const sessionTransition = useCallback(
        async (transitionId: string, objectId: string, validate?: boolean): Promise<void> => {
            if (!validate) {
                return commissionSessionStore.lifeCycleTransition(transitionId, objectId);
            }

            const isFormsValid = validateForm();

            if (isFormsValid) {
                return commissionSessionStore.lifeCycleTransition(transitionId, objectId);
            }

            notificationStore.onError(intlStore.formatMessage('validation.filledFields'));
        },
        [commissionSessionStore, notificationStore, intlStore, validateForm],
    );

    const {
        commonFormInfo,
        rulesFormInfo,
        generalizationTableModel,
        procedureAttributeTableModel,
        sessionProcedureModel,
    } = model;

    return (
        <Grid container direction="column">
            <Grid item>
                <CommissionSessionHeader
                    reloadKey={reloadKey}
                    commissionSessionModel={model}
                    onSubmit={onSubmit}
                    updateObjectPage={updateObjectPage}
                    sessionTransition={sessionTransition}
                />
            </Grid>
            <TotObjectMain>
                <Grid container>
                    <Grid item container direction="column" spacing={10}>
                        <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
                            <Grid item>
                                <CommissionSessionServiceInfo commissionSessionModel={model} />
                            </Grid>
                        </AuthorizationCheck>

                        <Grid item>
                            <ExpansionPanel defaultExpanded elevation={0}>
                                <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h3">
                                        1. <FormattedMessage id="commissionSession.blocksTitle.commonForm" />
                                    </Typography>
                                </TotObjectPanelSummary>
                                <TotObjectPanelDetails>
                                    <Switch>
                                        <Route
                                            path={[
                                                clientRoute.commissionSessionSettingEdit,
                                                clientRoute.commissionSessionSettingCreate,
                                            ]}
                                        >
                                            <Form
                                                form={commonFormInfo}
                                                onFormReady={onCommonFormReady}
                                                className="t-common-form"
                                            />
                                        </Route>
                                        <Route path={clientRoute.commissionSessionSetting}>
                                            <FormView
                                                locale={locale}
                                                form={commonFormInfo}
                                                onFormReady={onCommonFormReady}
                                            />
                                        </Route>
                                    </Switch>
                                </TotObjectPanelDetails>
                            </ExpansionPanel>
                        </Grid>

                        <Grid item>
                            <ExpansionPanel defaultExpanded elevation={0}>
                                <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h3">
                                        2. <FormattedMessage id="commissionSession.blocksTitle.rulesForm" />
                                    </Typography>
                                </TotObjectPanelSummary>

                                <TotObjectPanelDetails>
                                    <Switch>
                                        <Route
                                            path={[
                                                clientRoute.commissionSessionSettingEdit,
                                                clientRoute.commissionSessionSettingCreate,
                                            ]}
                                        >
                                            <Form
                                                form={rulesFormInfo}
                                                onFormReady={onRulesFormReady}
                                                className="t-rules-form"
                                            />
                                        </Route>
                                        <Route path={clientRoute.commissionSessionSetting}>
                                            <FormView
                                                locale={locale}
                                                form={rulesFormInfo}
                                                onFormReady={onRulesFormReady}
                                            />
                                        </Route>
                                    </Switch>
                                </TotObjectPanelDetails>
                            </ExpansionPanel>
                        </Grid>

                        <Grid item>
                            <Switch>
                                <Route
                                    exact
                                    path={[
                                        clientRoute.commissionSessionSetting,
                                        clientRoute.sessionProcedureCreate,
                                        clientRoute.sessionProcedureEdit,
                                    ]}
                                >
                                    <GeneralizationAttributesBlock model={generalizationTableModel} />
                                </Route>
                            </Switch>
                        </Grid>

                        <Switch>
                            <Route
                                exact
                                path={[
                                    clientRoute.commissionSessionSetting,
                                    clientRoute.sessionProcedureCreate,
                                    clientRoute.sessionProcedureEdit,
                                ]}
                            >
                                <Grid item>
                                    <SessionProceduresSetting
                                        model={sessionProcedureModel}
                                        procedureAttributeTableModel={procedureAttributeTableModel}
                                    />
                                </Grid>
                            </Route>
                        </Switch>
                    </Grid>
                </Grid>
            </TotObjectMain>
        </Grid>
    );
});
