import { action, observable } from 'mobx';
import { PfTemplateStore } from '../stores';
import { PfTemplateDTO } from '../types';

export class PfTemplateListModel {
    @observable private pfTemplateStore: PfTemplateStore;
    @observable pfTemplateList: PfTemplateDTO[] = [];

    constructor(pfTemplateStore: PfTemplateStore) {
        this.pfTemplateStore = pfTemplateStore;
        this.load();
    }

    @action.bound
    load(): void {
        const templateList = this.pfTemplateStore.templateList();
        this.setPfTemplateList(templateList);
    }

    @action.bound
    setPfTemplateList(pfTemplateList: PfTemplateDTO[]): void {
        this.pfTemplateList = pfTemplateList;
    }
}
