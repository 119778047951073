import { AxiosPromise } from 'axios';
import { History } from 'history';
import downloadFile from 'js-file-download';
import { action, computed, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { CommissionSessionListModel, CommissionSessionProcessModel, CommissionSessionSettingModel } from '../models';
import {
    CommissionSessionProcessDTO,
    CommissionSessionRowDTO,
    CommissionSessionSettingDTO,
    CompleteVotesDTO,
    DialogCategoryInfo,
    FullSubmission,
    GeneralizationCategoryInfoDTO,
    GeneralizationTableDTO,
    IdTitle,
    NewCommissionSessionDTO,
    ProcedureSubjectCommentDTO,
    ProcedureSubjectDecisionDTO,
    ProcedureSubjectListSettingDTO,
    ResultOfVotingDTO,
    RowsData,
    SessionProcedure,
    SessionProcedureAttributesDTO,
    SessionProcedureCreateInfo,
    SessionProcedureDTO,
    SessionProcedureTableInfoDTO,
    SubjectAttributeValuesDTO,
    SubjectCommentDTO,
    TableQueryData,
    TransitionsDTO,
} from '../types';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { Api } from './Api';
import { NotificationStore } from './NotificationStore';
import { RootStore } from './RootStore';

export class CommissionSessionStore {
    @observable private rootStore: RootStore;
    @observable showFormInfoValidation: boolean = false;
    @observable private readonly api: Api;
    @observable isUserVoteProcessing: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @computed
    get notification(): NotificationStore {
        return this.rootStore.notificationStore;
    }

    @action.bound
    setLoadingStart(): void {
        this.isUserVoteProcessing = true;
    }

    @action.bound
    setLoadingFinish(): void {
        this.isUserVoteProcessing = false;
    }

    @action.bound
    lifeCycleTransition(transitionId: string, sessionId: string): Promise<void> {
        return this.api
            .client(apiConfigs.commissionSessionLifeCycleTransition(transitionId, sessionId))
            .then((r) => r.data);
    }

    @action.bound
    getLifeCycleTransitions(sessionId: string): Promise<TransitionsDTO> {
        return this.api.client(apiConfigs.getCommissionSessionLifeCycleTransitions(sessionId)).then((r) => r.data);
    }

    @action.bound
    procedureLifeCycleTransition(transitionId: string, procedureId: string): Promise<void> {
        return this.api
            .client(apiConfigs.sessionProcedureLifeCycleTransition(transitionId, procedureId))
            .then((r) => r.data);
    }

    @action.bound
    getProcedureLifeCycleTransitions(procedureId: string): Promise<TransitionsDTO> {
        return this.api.client(apiConfigs.getSessionProcedureLifeCycleTransitions(procedureId)).then((r) => r.data);
    }

    @action.bound
    getCommissionSessionSettingModel(id: string): CommissionSessionSettingModel {
        const model = new CommissionSessionSettingModel(id, this);
        this.loadSessionSetting(id).then(model.load);
        return observable(model);
    }

    @action.bound
    getCommissionSessionProcessModel(id: string): CommissionSessionProcessModel {
        const model = new CommissionSessionProcessModel(id);
        this.loadSessionProcess(id).then(model.load);
        return observable(model);
    }

    @action.bound
    getCommissionSessionListModel(): CommissionSessionListModel {
        const model = new CommissionSessionListModel(this.api, this);
        return observable(model);
    }

    @action.bound
    loadSessionSetting(id: string): Promise<CommissionSessionSettingDTO> {
        return this.api
            .client(apiConfigs.commissionSessionSetting(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    loadSessionProcess(id: string): Promise<CommissionSessionProcessDTO> {
        return this.api
            .client(apiConfigs.commissionSessionProcess(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    createSession(dto: NewCommissionSessionDTO): Promise<string> {
        return this.api.client(apiConfigs.createCommissionSession(dto)).then((r) => r.data.id);
    }

    @action.bound
    deleteCommissionSession(id: string): Promise<void> {
        return this.api.client(apiConfigs.deleteCommissionSession(id)).then((r) => r.data);
    }

    @action.bound
    loadList(queryData: TableQueryData): Promise<RowsData<CommissionSessionRowDTO>> {
        return this.api
            .client(apiConfigs.sessionList(queryData))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            );
    }

    @action.bound
    exportListXls(queryData: TableQueryData): void {
        const filename = this.rootStore.intlStore.formatMessage('commissionSession.listTitle');
        this.api
            .client(apiConfigs.commissionSessionListXls(queryData))
            .then((r) => r.data)
            .then((f) => downloadFile(f, `${filename}.xlsx`, 'application/vnd.ms-excel'));
    }

    @action.bound
    saveForm(
        sessionId: string,
        commonFormSubmission: FullSubmission,
        rulesFormSubmission: FullSubmission,
    ): Promise<void> {
        return this.api
            .client(apiConfigs.saveCommissionSessionSettingForm(sessionId, commonFormSubmission, rulesFormSubmission))
            .then((r) => r.data);
    }

    @action.bound
    getGeneralizationCategoriesInfo(sessionId: string, attributeId = ''): Promise<DialogCategoryInfo> {
        return this.api.client(apiConfigs.getGeneralizationCategories(sessionId, attributeId)).then((r) => {
            return r.data;
        });
    }

    @action.bound
    getGeneralizationTableInfo(sessionId: string): Promise<GeneralizationTableDTO> {
        return this.api.client(apiConfigs.getGeneralizationTableInfo(sessionId)).then((r) => r.data);
    }

    @action.bound
    submitGeneralizationAttribute(sessionId: string, data: GeneralizationCategoryInfoDTO): Promise<void> {
        return this.api.client(apiConfigs.submitGeneralizationAttribute(sessionId, data)).then((r) => r.data);
    }

    @action.bound
    deleteGeneralizationAttribute(attributeId: string, sessionId: string): Promise<void> {
        return this.api.client(apiConfigs.deleteGeneralizationAttribute(attributeId, sessionId)).then((r) => r.data);
    }

    @action.bound
    editGeneralizationAttribute(
        attributeId: string,
        sessionId: string,
        data: GeneralizationCategoryInfoDTO,
    ): Promise<void> {
        return this.api
            .client(apiConfigs.editGeneralizationAttribute(attributeId, sessionId, data))
            .then((r) => r.data);
    }

    @action.bound
    loadProcedureList(sessionId: string): Promise<SessionProcedure[]> {
        return this.api.client(apiConfigs.sessionProceduresList(sessionId)).then((r) => r.data);
    }

    @action.bound
    createSessionProcedure(sessionId: string): Promise<SessionProcedureCreateInfo> {
        return this.api.client(apiConfigs.createSessionProcedure(sessionId)).then((r) => {
            return r.data;
        });
    }

    @action.bound
    deleteSessionProcedure(id: string): Promise<void> {
        return this.api.client(apiConfigs.deleteSessionProcedure(id)).then((r) => r.data);
    }

    @action.bound
    loadSessionProcedure(procedureId: string): Promise<SessionProcedureDTO> {
        return this.api.client(apiConfigs.loadSessionProcedure(procedureId)).then((r) => r.data);
    }

    @action.bound
    saveProcedureForm(procedureId: string, procedureFormSubmission: FullSubmission): Promise<void> {
        return this.api
            .client(apiConfigs.saveSessionProcedureForm(procedureId, procedureFormSubmission))
            .then((r) => r.data);
    }

    @action.bound
    sessionProcedureGeneralizationAttributeListSelectData(procedureId: string): Promise<IdTitle[]> {
        return this.api
            .client(apiConfigs.sessionProcedureGeneralizationAttributeListSelectData(procedureId))
            .then((r) => r.data);
    }

    @action.bound
    sessionProcedureGeneralizationAttributeList(procedureId: string): Promise<SessionProcedureTableInfoDTO> {
        return this.api.client(apiConfigs.sessionProcedureGeneralizationAttributeList(procedureId)).then((r) => r.data);
    }

    @action.bound
    sessionProcedureGeneralizationAttributeCreate(
        procedureId: string,
        data: SessionProcedureAttributesDTO,
    ): Promise<void> {
        return this.api
            .client(apiConfigs.sessionProcedureGeneralizationAttributeCreate(procedureId, data))
            .then((r) => r.data);
    }

    @action.bound
    sessionProcedureGeneralizationAttributeDelete(procedureAttributeId: string): Promise<void> {
        return this.api
            .client(apiConfigs.sessionProcedureGeneralizationAttributeDelete(procedureAttributeId))
            .then((r) => r.data);
    }

    @action.bound
    changeProcedurePosition(procedureId: string, newPosition: number): AxiosPromise<void> {
        return this.api.client(apiConfigs.changeProcedurePosition(procedureId, newPosition));
    }

    @action.bound
    getSessionProcedureSubjectList(
        sessionId: string,
        procedureId: string,
        data: TableQueryData,
    ): Promise<RowsData<SubjectAttributeValuesDTO>> {
        return this.api
            .client(apiConfigs.sessionProcedureSubjectList(sessionId, procedureId, data))
            .then((r) => r.data);
    }

    @action.bound
    getSessionProcedureSubjectListSetting(
        sessionId: string,
        procedureId: string,
    ): Promise<ProcedureSubjectListSettingDTO> {
        return this.api
            .client(apiConfigs.sessionProcedureSubjectListSetting(sessionId, procedureId))
            .then((r) => r.data);
    }

    @action.bound
    sessionProcedureSubjectListXls(queryData: TableQueryData, procedureId: string, sessionId: string): void {
        const filename = this.rootStore.intlStore.formatMessage('commissionSession.listTitle');
        this.api
            .client(apiConfigs.sessionProcedureSubjectListXls(queryData, procedureId, sessionId))
            .then((r) => r.data)
            .then((f) => downloadFile(f, `${filename}.xlsx`, 'application/vnd.ms-excel'));
    }

    @action.bound
    saveDecision(id: string, submission: FullSubmission, type: 'common' | 'private'): Promise<void> {
        return this.api
            .client(apiConfigs.saveDecision(id, submission, type))
            .then(() => {
                this.notification.onSuccess(
                    this.rootStore.intlStore.formatMessage('commissionSession.sessionProcedureSubjectList.successSave'),
                );
            })
            .catch((error) => {
                this.notification.onError(error.response.data);
            });
    }

    @action.bound
    saveResultOfVoting(id: string, data: ResultOfVotingDTO): Promise<void> {
        return this.api
            .client(apiConfigs.saveResultOfVoting(id, data))
            .then((r) => r.data)
            .catch((error) => {
                this.notification.onError(error.response.data);
            });
    }

    @action.bound
    checkCastingVoteStatus(procedureId: string) {
        return this.api
            .client(apiConfigs.checkCastingVoteStatus(procedureId))
            .then((r) => r.data)
            .catch((error) => {
                this.notification.onError(error.response.data);
            });
    }

    @action.bound
    getCompletedVotes(id: string): Promise<CompleteVotesDTO> {
        return this.api.client(apiConfigs.getCompletedVotes(id)).then((r) => r.data);
    }

    @action.bound
    sendVote(id: string): Promise<void> {
        return this.api
            .client(apiConfigs.sendVote(id))
            .then(() => {
                this.notification.onSuccess(
                    this.rootStore.intlStore.formatMessage(
                        'commissionSession.sessionProcedureSubjectList.completedVoting',
                    ),
                );
            })
            .catch((e) => {
                this.notification.onError(e.response.data);
            });
    }

    @action.bound
    commonDecisionTransition(decisionId: string): Promise<any> {
        return this.api.client(apiConfigs.commonDecisionTransition(decisionId)).then((res) => res.data);
    }

    @action.bound
    changeCommonDecisionState(decisionId: string, transitionId: string): Promise<void> {
        return this.api
            .client(apiConfigs.changeCommonDecisionState(decisionId, transitionId))
            .then(() => {
                this.notification.onSuccess(
                    this.rootStore.intlStore.formatMessage('commissionSession.sessionProcedureSubjectList.successSave'),
                );
            })
            .catch((e) => {
                this.notification.onError(e.response.data);
            });
    }
    @action.bound
    sessionProcedureSubjectPrivateDecisions(
        procedureId: string,
        subjectId: string,
    ): Promise<ProcedureSubjectDecisionDTO[]> {
        return this.api
            .client(apiConfigs.sessionProcedureSubjectPrivateDecisionList(procedureId, subjectId))
            .then((r) => r.data);
    }

    @action.bound
    sessionProcedureSubjectComments(procedureId: string, subjectId: string): Promise<ProcedureSubjectCommentDTO[]> {
        return this.api
            .client(apiConfigs.sessionProcedureSubjectCommentList(procedureId, subjectId))
            .then((r) => r.data);
    }

    @action.bound
    saveSubjectComment(id: string, data: SubjectCommentDTO): Promise<void> {
        return this.api
            .client(apiConfigs.saveSubjectComment(id, data))
            .then((r) => r.data)
            .catch((error) => {
                this.notification.onError(error.response.data);
                return Promise.reject(error);
            });
    }
}
