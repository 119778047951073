import { ExpansionPanel, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { TotObjectMain, TotObjectPanelDetails, TotObjectPanelSummary } from '../../components';
import { useReload, useStore } from '../../hooks';
import { CommissionSessionProcessModel } from '../../models';
import { RouteParamsDefault } from '../../types';
import { CommissionSessionHeader } from './commission-session-header';
import { DefaultContentContainer, NoDataText } from './generalization-table';
import { SessionProceduresProcess } from './session-procedure-process';

export const CommissionSessionProcessPage = observer((): JSX.Element => {
    const { commissionSessionStore } = useStore();
    const { id } = useParams<RouteParamsDefault>();
    const [reloadKey, reloadIncrement] = useReload();
    const { lifeCycleTransition, getCommissionSessionProcessModel } = commissionSessionStore;

    const model = useMemo<CommissionSessionProcessModel>(() => getCommissionSessionProcessModel(id), [id]);
    const { activeProcedureTitle } = model;

    const updateObjectPage = useCallback(async (): Promise<void> => {
        const dto = await commissionSessionStore.loadSessionProcess(id);
        reloadIncrement();
        model.load(dto);
    }, [commissionSessionStore, model, id, reloadIncrement]);

    return (
        <Grid container direction="column">
            <Grid item>
                <CommissionSessionHeader
                    reloadKey={reloadKey}
                    commissionSessionModel={model}
                    updateObjectPage={updateObjectPage}
                    sessionTransition={lifeCycleTransition}
                />
            </Grid>
            <TotObjectMain>
                <Grid container>
                    <Grid item container direction="column" spacing={10}>
                        <Grid item>
                            <ExpansionPanel defaultExpanded elevation={0} className="t-commission-info">
                                <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h3">
                                        <FormattedMessage id="commissionSession.blocksTitle.commissionInfo" />
                                    </Typography>
                                </TotObjectPanelSummary>
                                <TotObjectPanelDetails>
                                    {model.members.map((member) => member.fullName).join(', ') || (
                                        <DefaultContentContainer container justify="center" alignItems="center">
                                            <Grid item>
                                                <NoDataText>
                                                    <FormattedMessage id="common.noData" />
                                                </NoDataText>
                                            </Grid>
                                        </DefaultContentContainer>
                                    )}
                                </TotObjectPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                        <Grid item>
                            <SessionProceduresProcess
                                updateSessionInfo={updateObjectPage}
                                activeProcedureTitle={activeProcedureTitle}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectMain>
        </Grid>
    );
});
