const commissionSession: Record<string, string | Record<string, string | Record<string, string>>> = {
    listTitle: 'Sessions',
    create: 'Create session',
    newSession: 'New session',
    newProcedure: 'New procedure',
    createProcedure: 'Add procedure',
    editProcedure: 'Edit procedure',
    fields: {
        title: 'Title',
        process: 'LifeCycle',
        form: 'Session Basic Information Form',
        number: 'Number',
        state: 'State',
        commission: 'Commission',
        categories: 'Categories',
        startDate: 'Start date',
        endDate: 'End date',
    },
    filters: {
        attribute: 'Filters for attributes',
        procedure: 'Filters for past procedures',
    },
    procedureFields: {
        title: 'Procedure title',
        descr: 'Procedure description',
        process: 'Procedure`s LifeCycle',
        format: 'Procedure`s format',
        method: 'Procedure`s method',
        members: 'Procedure`s members',
    },
    actions: {
        deleteSession: 'Delete session',
        editSession: 'Edit session',
        toProcess: 'To process',
    },
    pagingInfo: 'Total {count, number} sessions. Displayed from {from, number} to {to, number}',
    confirmDeletionInfoText: 'Are you sure you want to delete the session "{title}"?',
    confirmProcedureDeletionInfoText: 'Вы действительно хотите удалить процедуру "{title}"?',
    blocksTitle: {
        commonForm: 'Common info',
        rulesForm: 'Session rules',
        generalizationTable: 'Generalization table',
        procedures: 'Session procedures',
        commissionInfo: 'Commission information',
    },

    generalizationTable: {
        addAttribute: 'Add generalization attribute',
        dialogAddTitle: 'Adding an attribute',
        dialogEditTitle: 'Editing an attribute',
        attributeTitle: 'Generalization attribute name',
        subjectAttribute: 'Subject attribute "{category}"',
        confirmDeletionInfoText: 'Are you sure you want to delete the generalization attribute "{title}"?',
        tooltip: 'You must customize the generalization table!',
    },

    sessionProcedureTransition: {
        transitionError: 'Not all objects have a common decision. You can exercise your casting vote',
        confirmTransition: 'Leave undefined decisions',
        cancelTransition: 'Use the casting vote',
    },

    sessionProcedureTable: {
        title: 'Objects table',
        attribute: 'Generalized attribute from generalization table',
        confirmDeletionInfoText:
            'Are you sure you want to remove the generalization attribute from the procedure "{title}"?',
        confirmDecision: 'Confirm the decision',
    },

    sessionProcedureSubjectList: {
        title: 'Objects table',
        privateDecision: 'Private decision',
        commonDecision: 'Common decision',
        voting: 'Voting',
        resultOfVoting: 'Result of voting',
        voted: 'Voted',
        enterResultOfVoting: 'Enter the voting result',
        addResultOfVoting: 'Adding a voting result',
        successSave: 'Your decision was saved successfully',
        toVote: 'To vote',
        completedVoting: 'Voting completed successfully',
        voteIsYetToBeProcessed: 'Your decision has not been processed yet',
        pagingInfo: 'Total {count, number} subjects. Displayed from {from, number} to {to, number}',
        privateDecisionsDialogTitle: 'Results of voting',
        showAllPrivateDecisions: 'Show all decisions',
        privateDecisionsTable: {
            number: '#',
            member: 'Full name',
            mark: 'Mark',
            noMark: 'No mark',
            date: 'Date',
        },
        commentDialogTitle: 'Comment',
        commentField: 'Comment',
        createComment: 'Create a comment',
        editComment: 'Edit a comment',
        commentDialogListTitle: 'Comments',
        allComments: 'All comments',
        commentsTable: {
            member: 'User',
            comment: 'Comment',
        },
    },
    commissionState: {
        Ready: 'Ready',
        Voting: 'Vote',
        CommonDecisionMaking: 'Formation of a common solution',
        Finishes: 'Procedure completed',
        Skipped: 'Procedure skipped',
        Draft: 'Draft',
    },
};

export default commissionSession;
