const common: Record<string, string | Record<string, string>> = {
    add: 'Add',
    edit: 'Edit',
    close: 'Close',
    error: 'Error',
    errorText: 'Something went wrong',
    save: 'Save',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm',
    noData: 'No data',
    noOptions: 'No options',
    from: 'from',
    to: 'to',
    fromToNumber: 'from {from, number} to {to, number}',
    until: 'until',
    State: 'State',
    rowsPerPage: 'Rows per page:',
    pagingInfo: '{from, number}-{to, number} of {count, number}',
    resetFilters: 'Reset',
    filters: 'Filters',
    all: 'All',
    actions: 'Actions',
    export: 'Export',
    moveRow: 'Move row',
    confirmDeletion: 'Confirm deletion',
    saveChanges: 'Save changes',
    dropzoneText: 'Drag and drop a file here or click',
    pageNotFound: 'Page not found',
    actionNotAllowed: 'Action not allowed',
    errorOccurred: 'An error has occurred',
    withoutNumber: 'Without a number',
    readonlyValidationMsg: 'The form is filled out incorrectly. Go to <a>edit</a> to fix errors.',
    lifeCycleConfirmTitle: 'Status change',
    lifeCycleConfirmText: 'Status will be changed to "{toState}"',
    amountOfDays: '{count, number} day{count, plural, one {} other {s}}',
    serviceInfo: 'Service information',
    serviceInfoFields: {
        created: 'Created',
        state: 'State',
        author: 'Author',
        lastModified: 'Last modified',
        lifeCycle: 'Life cycle',
        form: 'Form',
        commonForm: 'Session Basic Information Form',
        rulesForm: 'Form of session rules',
    },
    invalidDateMessage: 'Invalid date format',
    maxDateMessage: 'Date should not be after than the current date',
    consentDataUsePolicy: 'Consent to the processing of personal data',
    pageLeaveMessage: 'Are you sure you want to leave this page?',
    downloadFiles: 'Download files',
    downloadFile: 'Download {fileName}',
    user: 'User',
    setting: 'Setting',
    description: 'Description',
    ok: 'Ok',
};

export default common;
